<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 d-flex vh-100">
        <form class="form--login" action="" method="post" @submit="checkForm">
          <fieldset>
            <div class="d-flex align-items-center mb-5 justify-content-center">
              <img src="../assets/logo-unibanco-jovem-de-futuro-letra-azul.png" class="logo-sidebar" alt="Logo do Insituto Unibanco">
            </div>
            <legend class="title pb-3">Seja bem-vindo ao Mapa de Atores</legend>

            <hr class="mb-4">

            <div class="mb-3">
              <label for="inputEmail1" class="form-label">E-mail</label>
              <input type="email" class="form-control" id="inputEmail1" name="email" placeholder="Digite o seu e-mail" :disabled="formSendingState" required>
            </div>
            <div class="mb-2">
              <label for="inputPassword1" class="form-label">Senha</label>
              <input type="password" class="form-control" id="inputPassword1" name="password" placeholder="Digite a sua senha" :disabled="formSendingState" required>
            </div>
            <div class="text-end mb-4">
              <a href="javascript: void(0)">Esqueci minha senha</a>
            </div>
            <div class="text-center">
              <button v-show="!formSendingState" type="submit" class="btn btn-primary w-75">
                Entrar
              </button>
              <button v-show="formSendingState" type="button" class="btn btn-primary w-75" disabled>
                Validando ...
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              </button>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import LoginService from '../libs/services/login.js';

export default {
  name: 'Login',
  data () {
    return {
      formSendingState: false
    };
  },
  mounted () {
    document.body.classList.add('login-body-bg');
  },
  unmounted () {
    document.body.classList.remove('login-body-bg');
  },
  methods: {
    async checkForm (event) {
      event.preventDefault();

      /** @type {HTMLFormElement} */
      const form = event.target;
      const formData = new FormData(form);

      if (!form.checkValidity()) return;
      // Setado o estado do formulário para enviando
      this.formSendingState = true;

      const { logged } = await LoginService.login(
        // @ts-expect-error
        formData.get('email'),
        formData.get('password')
      );

      if (logged) {
        form.reset();
        this.$router.push({ name: 'Dashboard' });
      }

      // Restaura o estado do formulário para o padrão (esperando interação do usuário)
      this.formSendingState = false;
    }
  }
};
</script>

<style>
.form--login {
  width: 100%;
  max-width: 267px;
  margin: auto 8.33% auto auto;
}
.form--login .title {
  color: var(--bs-primary);
  font-size: 16px;
}
body.login-body-bg {
  background-image: url('../assets/bg-login.png');
  background-position: left top;
  background-size: contain;
  background-repeat: no-repeat;
}
</style>
