import axios from 'axios';

export default class LoginService {
  /**
   * @param {string} user
   * @param {string} password
   * @returns {Promise<{ logged: boolean, message: string }>}
   */
  static async login (user, password) {
    try {
      /**
       * Endpoint statless para autenticação
       */
      const loginResponse = await axios.post('/api/sanctum/token', {
        email: user,
        password: password,
        device_name: 'use_padrao'
      });

      const token = loginResponse.data.token;

      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      localStorage.setItem('token', token);

      return { logged: true, message: 'sucesso' };
    } catch (error) {
      return { logged: false, message: 'erro' };
    }
  }
}
